import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import Flag from 'react-world-flags';
import GigsOverlay from './GigsOverlay';
import { Switch } from '@headlessui/react';

export default function Gigs() {
  const [gigs, setGigs] = useState([]);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedGig, setSelectedGig] = useState(null);
  const [mode, setMode] = useState('add');

  // Toggle state for Gigs visibility
  const [isGigsVisible, setIsGigsVisible] = useState(false);
  const [isToggleChanged, setIsToggleChanged] = useState(false);

  // Fetch gig data from Firestore
  const fetchGigs = async () => {
    const gigsCollection = collection(db, 'gigs');
    const gigDocs = await getDocs(gigsCollection);
    const gigsData = gigDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setGigs(gigsData);
  };

  useEffect(() => {
    fetchGigs();

    const fetchGigsVisibility = async () => {
      const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
      const sectionsDoc = await getDoc(sectionsDocRef);
      if (sectionsDoc.exists()) {
        const sectionData = sectionsDoc.data();
        setIsGigsVisible(sectionData.gigs || false);
      }
    };

    fetchGigsVisibility();
  }, []);

  const handleToggleChange = (value) => {
    setIsGigsVisible(value);
    setIsToggleChanged(true);
  };

  const handleSaveToggleChanges = async () => {
    try {
      const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
      await updateDoc(sectionsDocRef, { gigs: isGigsVisible });
      setIsToggleChanged(false);
    } catch (error) {
      console.error("Error saving gigs visibility:", error);
    }
  };

  const handleAddGig = () => {
    setMode('add');
    setSelectedGig(null);
    setIsOverlayOpen(true);
  };

  const handleEditGig = (gig) => {
    setMode('edit');
    setSelectedGig(gig);
    setIsOverlayOpen(true);
  };

  const handleSaveGig = async (savedGig) => {
    setIsOverlayOpen(false);
    
    // If savedGig is null, it signals deletion; re-fetch gigs from Firestore
    if (!savedGig) {
      await fetchGigs();
    } else if (mode === 'edit') {
      setGigs(prevGigs => prevGigs.map(gig => (gig.id === savedGig.id ? savedGig : gig)));
    } else {
      setGigs(prevGigs => [...prevGigs, savedGig]);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Gigs</h1>
          <p className="mt-2 text-sm text-gray-700">Add your past gigs here</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={handleAddGig}
            className="block rounded-md bg-user-dark-orange px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-user-light-orange"
          >
            Add Gig
          </button>
        </div>
      </div>

      {/* Toggle for Gigs visibility */}
      <div className="flex items-center my-4 space-x-4">
        <label className="text-sm font-medium text-gray-900">Gigs Visibility</label>
        <Switch
          checked={isGigsVisible}
          onChange={(value) => handleToggleChange(value)}
          className={`relative inline-flex h-6 w-11 items-center rounded-full ${isGigsVisible ? 'bg-user-dark-orange' : 'bg-gray-200'}`}
        >
          <span className="sr-only">Toggle gigs visibility</span>
          <span className={`transform inline-block h-5 w-5 rounded-full bg-white transition ${isGigsVisible ? 'translate-x-5' : 'translate-x-0'}`} />
        </Switch>
      </div>

      {isToggleChanged && (
        <button
          onClick={handleSaveToggleChanges}
          className="inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange"
        >
          Save Changes
        </button>
      )}

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Country</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">City</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Venue</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                  <th className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {gigs.map((gig) => (
                  <tr key={gig.id}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <Flag code={gig.country} className="inline-block w-6 h-4 mr-2" />
                      {gig.country}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{gig.city}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{gig.venue}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{gig.date}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => handleEditGig(gig)}
                        className="text-user-dark-orange hover:text-user-light-orange"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isOverlayOpen && (
        <GigsOverlay
          mode={mode}
          gigData={selectedGig}
          onClose={() => setIsOverlayOpen(false)}
          onSave={handleSaveGig}
        />
      )}
    </div>
  );
}
