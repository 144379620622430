import React, { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL, uploadBytes } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

const storage = getStorage();

export default function PhotoOverlay({ onClose, onSave, existingPhotos }) {
  const [storagePhotos, setStoragePhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [uploading, setUploading] = useState(false);
  const existingPhotoPaths = existingPhotos.map(photo => photo.filePath); // Paths of existing photos

  useEffect(() => {
    // Fetch photos from "photos" folder in Firebase Storage
    const fetchPhotos = async () => {
      const folderRef = ref(storage, 'photos');
      const result = await listAll(folderRef);

      const filePromises = result.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        return {
          fileName: itemRef.name,
          filePath: `photos/${itemRef.name}`,
          fileUrl: url,
        };
      });

      const allPhotos = await Promise.all(filePromises);
      // Filter out photos that already exist in the photos collection
      const filteredPhotos = allPhotos.filter(photo => !existingPhotoPaths.includes(photo.filePath));
      setStoragePhotos(filteredPhotos);
    };

    fetchPhotos().catch(error => console.error("Error fetching photos:", error));
  }, [existingPhotoPaths]);

  const handleSave = async () => {
    if (selectedPhoto) {
      try {
        await addDoc(collection(db, 'photos'), selectedPhoto);
        onSave(selectedPhoto); // Notify parent to refresh the photos list
        onClose();
      } catch (error) {
        console.error("Error saving photo:", error);
      }
    }
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    const fileRef = ref(storage, `photos/${file.name}`);
    try {
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      const newPhoto = {
        fileName: file.name,
        filePath: `photos/${file.name}`,
        fileUrl: url,
      };
      setStoragePhotos((prevPhotos) => [...prevPhotos, newPhoto]);
    } catch (error) {
      console.error("Error uploading photo:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full">
        <h2 className="text-xl font-semibold mb-4">Select a Photo</h2>

        {/* Image Grid */}
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {storagePhotos.map((photo) => (
            <li
              key={photo.filePath}
              className={`relative cursor-pointer ${selectedPhoto?.filePath === photo.filePath ? 'ring-2 ring-green-500' : ''}`}
              onClick={() => setSelectedPhoto(photo)}
            >
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100">
                <img src={photo.fileUrl} alt={photo.fileName} className="pointer-events-none object-cover group-hover:opacity-75" />
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{photo.fileName}</p>
            </li>
          ))}
        </ul>

        {/* Action Buttons */}
        <div className="mt-6 flex justify-end space-x-4">
          <label className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm cursor-pointer hover:bg-indigo-500 focus:outline-none">
            {uploading ? "Uploading..." : "Upload Photo"}
            <input type="file" className="hidden" onChange={handleUpload} />
          </label>

          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-500 focus:outline-none"
            disabled={!selectedPhoto}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
