import { useState, useEffect } from 'react';

export default function useCountries() {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryList = data.map((country) => ({
          name: country.name.common,
          code: country.cca2, // ISO Alpha-2 code, compatible with react-world-flags
        }));

        // Sort countries alphabetically by name for dropdown display
        countryList.sort((a, b) => a.name.localeCompare(b.name));

        setCountries(countryList);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  return countries;
}
