import { useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react';
import {
  Bars3Icon,
  CalendarDateRangeIcon,
  ChartPieIcon,
  ArrowLeftStartOnRectangleIcon,
  WrenchScrewdriverIcon,
  FolderIcon,
  PhotoIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Bio from '../components/bio';
import Media from '../components/media';
import Photos from '../components/photos';
import Gigs from '../components/gigs';
import Rider from '../components/rider';
import { useAuth } from '../context/AuthContext';

const initialNavigation = [
  { name: 'Bio', icon: UsersIcon },
  { name: 'Media', icon: FolderIcon },
  { name: 'Photos', icon: PhotoIcon },
  { name: 'Gigs', icon: CalendarDateRangeIcon },
  { name: 'Rider', icon: WrenchScrewdriverIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState('Bio');
  const { logout } = useAuth();

  const handleNavigationClick = (name) => {
    setActiveComponent(name);
    setSidebarOpen(false); // Close the sidebar when a section is selected
  };

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = '/';
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Bio':
        return <Bio />;
      case 'Media':
        return <Media />;
      case 'Photos':
        return <Photos />;
      case 'Gigs':
        return <Gigs />;
      case 'Rider':
        return <Rider />;
      default:
        return <div>Welcome to the Dashboard</div>;
    }
  };

  return (
    <>
      <div>
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />
          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-user-dark-orange px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {initialNavigation.map((item) => (
                          <li key={item.name}>
                            <button
                              onClick={() => handleNavigationClick(item.name)}
                              className={classNames(
                                item.name === activeComponent
                                  ? 'bg-user-light-orange text-white'
                                  : 'text-indigo-200 hover:bg-user-light-orange hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className={classNames(
                                  item.name === activeComponent ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                                  'h-6 w-6 shrink-0',
                                )}
                              />
                              {item.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <button
                        onClick={handleLogout}
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
                      >
                        <ArrowLeftStartOnRectangleIcon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                        />
                        Logout
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-user-dark-orange px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">

            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {initialNavigation.map((item) => (
                      <li key={item.name}>
                        <button
                          onClick={() => handleNavigationClick(item.name)}
                          className={classNames(
                            item.name === activeComponent
                              ? 'bg-user-light-orange text-white'
                              : 'text-indigo-200 hover:bg-user-light-orange hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <item.icon
                            aria-hidden="true"
                            className={classNames(
                              item.name === activeComponent ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                              'h-6 w-6 shrink-0',
                            )}
                          />
                          {item.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <button
                    onClick={handleLogout}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-user-light-orange hover:text-white"
                  >
                    <ArrowLeftStartOnRectangleIcon
                      aria-hidden="true"
                      className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                    />
                    Logout
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4  bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              {renderComponent()}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
