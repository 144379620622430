import React, { useState } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

export default function MediaOverlay({ onClose, onSave, mediaData = {}, mode = 'add' }) {
  const [title, setTitle] = useState(mediaData?.title || '');
  const [description, setDescription] = useState(mediaData?.description || '');
  const [url, setUrl] = useState(mediaData?.url || '');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // Determine the overlay title based on the mode
  const overlayTitle = mode === 'edit' ? 'Edit Media' : 'Add Media';

  const handleDelete = async () => {
    if (mediaData && mediaData.id) {
      try {
        await deleteDoc(doc(db, 'media', mediaData.id));
        onClose(); // Close the overlay after deletion
        onSave(null); // Signal deletion to parent
      } catch (error) {
        console.error('Error deleting media:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-xl font-semibold mb-4">{overlayTitle}</h2>
        <div className="grid grid-cols-1 gap-4">
          {/* Title Field */}
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-900">Title</label>
            <input
              type="text"
              id="title"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          {/* Description Field */}
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-900">Description</label>
            <input
              type="text"
              id="description"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {/* URL Field */}
          <div>
            <label htmlFor="url" className="block text-sm font-medium text-gray-900">URL</label>
            <input
              type="text"
              id="url"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none"
          >
            Cancel
          </button>
          {mode === 'edit' && (
            <button
              onClick={() => setShowDeleteConfirmation(true)}
              className="px-4 py-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-500 focus:outline-none"
            >
              Delete
            </button>
          )}
          <button
            onClick={() => onSave({ title, description, url })}
            className="px-4 py-2 bg-user-dark-orange text-white rounded-md shadow-sm hover:bg-user-light-orange focus:outline-none"
          >
            Save
          </button>
        </div>

        {/* Confirmation modal for delete */}
        {showDeleteConfirmation && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-sm w-full">
              <h3 className="text-lg font-semibold mb-4">Are you sure you want to delete?</h3>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowDeleteConfirmation(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-500 focus:outline-none"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
