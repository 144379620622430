import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import BioImageOverlay from './BioImageOverlay';
import { Switch } from '@headlessui/react';

export default function Bio() {
  // Existing state management...
  const [bioText, setBioText] = useState('');
  const [originalBioText, setOriginalBioText] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [heroImageUrl, setHeroImageUrl] = useState('');
  const [bioImageUrl, setBioImageUrl] = useState('');
  const [newRelease, setNewRelease] = useState('');
  const [newReleaseUrl, setNewReleaseUrl] = useState('');
  const [originalNewRelease, setOriginalNewRelease] = useState('');
  const [originalNewReleaseUrl, setOriginalNewReleaseUrl] = useState('');
  const [isNewReleaseModified, setIsNewReleaseModified] = useState(false);
  const [showImageOverlay, setShowImageOverlay] = useState(false);
  const [targetField, setTargetField] = useState(null);

  // New state for Stat section
  const [selectedStat, setSelectedStat] = useState('Stat Small');
  const [statText, setStatText] = useState('');
  const [statDescription, setStatDescription] = useState('');
  const [originalStatText, setOriginalStatText] = useState('');
  const [originalStatDescription, setOriginalStatDescription] = useState('');
  const [isStatModified, setIsStatModified] = useState(false);

  // New states for bio and stat visibility toggles
  const [isBioVisible, setIsBioVisible] = useState(false);
  const [isStatVisible, setIsStatVisible] = useState(false);
  const [isToggleChanged, setIsToggleChanged] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bioDocRef = doc(db, 'bio', 'FTyLZ57XHRuAjCjdr37u');
        const bioDoc = await getDoc(bioDocRef);

        if (bioDoc.exists()) {
          const data = bioDoc.data();
          setBioText(data.bioText || '');
          setOriginalBioText(data.bioText || '');
          setHeroImageUrl(data.heroImageUrl || '');
          setBioImageUrl(data.bioImageUrl || '');
          setNewRelease(data.newRelease || '');
          setNewReleaseUrl(data.newReleaseUrl || '');
        }

        const statsDocRef = doc(db, 'stats', '66JElIgfW6RXqjdoxXAm'); // Update with actual doc ID
        const statsDoc = await getDoc(statsDocRef);

        if (statsDoc.exists()) {
          const statsData = statsDoc.data();
          setOriginalStatText(statsData.stat1 || '');
          setOriginalStatDescription(statsData.stat1Description || '');
          loadStatData(selectedStat);
        }

        // Fetch section visibility states
        const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
        const sectionsDoc = await getDoc(sectionsDocRef);
        if (sectionsDoc.exists()) {
          const sectionData = sectionsDoc.data();
          setIsBioVisible(sectionData.bio);
          setIsStatVisible(sectionData.stat);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Function to handle toggle changes
  const handleToggleChange = (field, value) => {
    if (field === 'bio') setIsBioVisible(value);
    if (field === 'stat') setIsStatVisible(value);
    setIsToggleChanged(true);
  };

  // Function to save the toggle state changes
  const handleSaveToggleChanges = async () => {
    try {
      const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
      await updateDoc(sectionsDocRef, {
        bio: isBioVisible,
        stat: isStatVisible,
      });
      setIsToggleChanged(false);
    } catch (error) {
      console.error("Error saving section visibility:", error);
    }
  };

  // Existing function definitions

  const loadStatData = (statType) => {
    setSelectedStat(statType);
    const statsDocRef = doc(db, 'stats', '66JElIgfW6RXqjdoxXAm');
    
    getDoc(statsDocRef)
      .then((statsDoc) => {
        const statsData = statsDoc.data();
        
        if (statType === 'Stat Small') {
          setStatText(statsData.stat1 || '');
          setStatDescription(statsData.stat1Description || '');
          setOriginalStatText(statsData.stat1 || '');
          setOriginalStatDescription(statsData.stat1Description || '');
        } else if (statType === 'Stat Large') {
          setStatText(statsData.stat2 || '');
          setStatDescription(statsData.stat2Description || '');
          setOriginalStatText(statsData.stat2 || '');
          setOriginalStatDescription(statsData.stat2Description || '');
        } else if (statType === 'Stat Medium') {
          setStatText(statsData.stat3 || '');
          setStatDescription(statsData.stat3Description || '');
          setOriginalStatText(statsData.stat3 || '');
          setOriginalStatDescription(statsData.stat3Description || '');
        }
      })
      .catch((error) => console.error("Error loading stat data:", error));
  };

  const handleBioTextChange = (e) => {
    setBioText(e.target.value);
    setIsModified(e.target.value !== originalBioText);
  };

  const handleSave = async () => {
    const bioDocRef = doc(db, 'bio', 'FTyLZ57XHRuAjCjdr37u');
    try {
      await updateDoc(bioDocRef, { bioText });
      setOriginalBioText(bioText);
      setIsModified(false);
    } catch (error) {
      console.error("Error saving bio text:", error);
    }
  };

  const handleNewReleaseChange = (e) => {
    setNewRelease(e.target.value);
    setIsNewReleaseModified(e.target.value !== originalNewRelease || newReleaseUrl !== originalNewReleaseUrl);
  };

  const handleNewReleaseUrlChange = (e) => {
    setNewReleaseUrl(e.target.value);
    setIsNewReleaseModified(newRelease !== originalNewRelease || e.target.value !== originalNewReleaseUrl);
  };

  const handleSaveNewRelease = async () => {
    const bioDocRef = doc(db, 'bio', 'FTyLZ57XHRuAjCjdr37u');
    try {
      await updateDoc(bioDocRef, { newRelease, newReleaseUrl });
      setOriginalNewRelease(newRelease);
      setOriginalNewReleaseUrl(newReleaseUrl);
      setIsNewReleaseModified(false);
    } catch (error) {
      console.error("Error saving new release info:", error);
    }
  };

  const openImageOverlay = (field) => {
    setTargetField(field);
    setShowImageOverlay(true);
  };

  const handleSaveImage = (selectedImageUrl) => {
    const bioDocRef = doc(db, 'bio', 'FTyLZ57XHRuAjCjdr37u');
    updateDoc(bioDocRef, { [targetField]: selectedImageUrl })
      .then(() => {
        if (targetField === 'heroImageUrl') setHeroImageUrl(selectedImageUrl);
        if (targetField === 'bioImageUrl') setBioImageUrl(selectedImageUrl);
        setShowImageOverlay(false);
      })
      .catch((error) => console.error("Error saving image URL:", error));
  };

  const handleStatChange = (e) => {
    setStatText(e.target.value);
    setIsStatModified(e.target.value !== originalStatText || statDescription !== originalStatDescription);
  };

  const handleDescriptionChange = (e) => {
    setStatDescription(e.target.value);
    setIsStatModified(statText !== originalStatText || e.target.value !== originalStatDescription);
  };

  const handleSaveStat = async () => {
    const statsDocRef = doc(db, 'stats', '66JElIgfW6RXqjdoxXAm');
    const fieldToUpdate = selectedStat === 'Stat Small' ? { stat1: statText, stat1Description: statDescription } 
                      : selectedStat === 'Stat Large' ? { stat2: statText, stat2Description: statDescription }
                      : { stat3: statText, stat3Description: statDescription };

    try {
      await updateDoc(statsDocRef, fieldToUpdate);
      setOriginalStatText(statText);
      setOriginalStatDescription(statDescription);
      setIsStatModified(false);
    } catch (error) {
      console.error("Error saving stat:", error);
    }
  };
  return (
    <div>
          <h2 className="text-xl font-semibold mb-4">Section Visibility</h2>
      <div className="flex items-center mb-4 space-x-8">
        <div className="flex items-center space-x-4">
          <label className="text-sm font-medium text-gray-900">Bio</label>
          <Switch
            checked={isBioVisible}
            onChange={(value) => handleToggleChange('bio', value)}
            className={`relative inline-flex h-6 w-11 items-center rounded-full ${isBioVisible ? 'bg-user-dark-orange' : 'bg-gray-200'}`}
          >
            <span className="sr-only">Toggle Bio visibility</span>
            <span className={`transform inline-block h-5 w-5 rounded-full bg-white transition ${isBioVisible ? 'translate-x-5' : 'translate-x-0'}`} />
          </Switch>
        </div>

        <div className="flex items-center space-x-4">
          <label className="text-sm font-medium text-gray-900">Stats</label>
          <Switch
            checked={isStatVisible}
            onChange={(value) => handleToggleChange('stat', value)}
            className={`relative inline-flex h-6 w-11 items-center rounded-full ${isStatVisible ? 'bg-user-dark-orange' : 'bg-gray-200'}`}
          >
            <span className="sr-only">Toggle Stats visibility</span>
            <span className={`transform inline-block h-5 w-5 rounded-full bg-white transition ${isStatVisible ? 'translate-x-5' : 'translate-x-0'}`} />
          </Switch>
        </div>
      </div>

      {isToggleChanged && (
        <button
          onClick={handleSaveToggleChanges}
          className="inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange"
        >
          Save Changes
        </button>
      )}
      {/* Display the Hero Image */}
      <h2 className="text-xl font-semibold mb-2">Hero Image</h2>
      {heroImageUrl && (
        <div className="mb-4 max-h-[500px] h-[500px] w-full overflow-hidden rounded-md shadow">
          <img
            src={heroImageUrl}
            alt="Hero"
            className="w-full h-full object-contain"
          />
        </div>
      )}
      <div className="mb-4">
        <button
          className="inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => openImageOverlay('heroImageUrl')}
        >
          Change Hero Image
        </button>
      </div>

            {/* New Release Text and Link Inputs */}
            <h2 className="text-xl font-semibold mb-2 mt-6">Banner</h2>
      <div className="mt-2 flex flex-col sm:flex-row sm:space-x-4">
        <div className="flex-1 mb-4 sm:mb-0">
          <label htmlFor="newRelease" className="block text-sm font-medium leading-6 text-gray-900">
            Banner Text
          </label>
          <textarea
            id="newRelease"
            name="newRelease"
            rows={2}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-user-dark-orange sm:text-sm sm:leading-6"
            value={newRelease}
            onChange={handleNewReleaseChange}
          />
        </div>

        <div className="flex-1 pt-10">
          <label htmlFor="newReleaseUrl" className="block text-sm font-medium leading-6 text-gray-900">
            Banner Link URL
          </label>
          <textarea
            id="newReleaseUrl"
            name="newReleaseUrl"
            rows={1}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-user-dark-orange0 sm:text-sm sm:leading-6"
            value={newReleaseUrl}
            onChange={handleNewReleaseUrlChange}
          />
        </div>
      </div>

      {isNewReleaseModified && (
        <button
          onClick={handleSaveNewRelease}
          className="mt-4 inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save New Banner
        </button>
      )}

      {/* Display the Bio Image */}
      <h2 className="text-xl font-semibold mb-2 pt-10">Bio Image</h2>
      {bioImageUrl && (
        <div className="mb-4 max-h-[500px] h-[500px] w-full overflow-hidden rounded-md shadow">
          <img
            src={bioImageUrl}
            alt="Bio"
            className="w-full h-full object-contain"
          />
        </div>
      )}
      <div className="mb-4">
        <button
          className="inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => openImageOverlay('bioImageUrl')}
        >
          Change Bio Image
        </button>
      </div>

      {/* Bio Text Area */}
      <h2 className="text-xl font-semibold mb-2">Bio Text</h2>
      <div className="mt-2">
        <textarea
          id="bioText"
          name="bioText"
          rows={4}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-user-dark-orange sm:text-sm sm:leading-6"
          value={bioText}
          onChange={handleBioTextChange}
          placeholder="Loading bio text..."
        />
      </div>
      {isModified && (
        <button
          onClick={handleSave}
          className="mt-4 inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      )}

      {/* Stat Section */}
      <h2 className="text-xl font-semibold mb-2 mt-6">Stats</h2>
      <div className="mt-2 grid grid-cols-2 gap-4">
        {/* Dropdown for Stat Selection */}
        <div>
          <label htmlFor="statType" className="block text-sm font-medium leading-6 text-gray-900">
            Select Stat
          </label>
          <select
            id="statType"
            name="statType"
            value={selectedStat}
            onChange={(e) => loadStatData(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value="Stat Small">Stat Small</option>
            <option value="Stat Large">Stat Large</option>
            <option value="Stat Medium">Stat Medium</option>
          </select>
        </div>

        {/* Stat Text and Description Text Areas */}
        <div className="flex flex-col space-y-4">
          <div>
            <label htmlFor="statText" className="block text-sm font-medium leading-6 text-gray-900">
              Stat
            </label>
            <textarea
              id="statText"
              name="statText"
              rows={1}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-user-dark-orange sm:text-sm sm:leading-6"
              value={statText}
              onChange={handleStatChange}
            />
          </div>

          <div>
            <label htmlFor="statDescription" className="block text-sm font-medium leading-6 text-gray-900">
              Description
            </label>
            <textarea
              id="statDescription"
              name="statDescription"
              rows={1}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-user-dark-orange sm:text-sm sm:leading-6"
              value={statDescription}
              onChange={handleDescriptionChange}
            />
          </div>
        </div>
      </div>

      {isStatModified && (
        <button
          onClick={handleSaveStat}
          className="mt-4 inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save Stat
        </button>
      )}

      {/* Render BioImageOverlay if showImageOverlay is true */}
      {showImageOverlay && (
        <BioImageOverlay
          onClose={() => setShowImageOverlay(false)}
          onSave={handleSaveImage}
          targetField={targetField}
        />
      )}
    </div>
  );
}
