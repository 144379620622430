import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import MediaOverlay from './MediaOverlay';
import { Switch } from '@headlessui/react';

export default function Media() {
  const [mediaItems, setMediaItems] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [overlayMode, setOverlayMode] = useState('add');

  // Toggle states
  const [isMediaVisible, setIsMediaVisible] = useState(false);
  const [isToggleChanged, setIsToggleChanged] = useState(false);

  useEffect(() => {
    const fetchMediaItems = async () => {
      const mediaCollection = collection(db, 'media');
      const mediaSnapshot = await getDocs(mediaCollection);
      const mediaData = mediaSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setMediaItems(mediaData);
    };

    const fetchMediaVisibility = async () => {
      const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
      const sectionsDoc = await getDoc(sectionsDocRef);
      if (sectionsDoc.exists()) {
        const sectionData = sectionsDoc.data();
        setIsMediaVisible(sectionData.media || false);
      }
    };

    fetchMediaItems();
    fetchMediaVisibility();
  }, []);

  // Handle toggle state changes
  const handleToggleChange = (value) => {
    setIsMediaVisible(value);
    setIsToggleChanged(true);
  };

  // Save toggle changes
  const handleSaveToggleChanges = async () => {
    try {
      const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
      await updateDoc(sectionsDocRef, { media: isMediaVisible });
      setIsToggleChanged(false);
    } catch (error) {
      console.error("Error saving media visibility:", error);
    }
  };

  const openAddOverlay = () => {
    setOverlayMode('add');
    setSelectedMedia(null); // Clear selected media for new entry
    setShowOverlay(true);
  };

  const openEditOverlay = (media) => {
    setOverlayMode('edit');
    setSelectedMedia(media);
    setShowOverlay(true);
  };

  const convertToEmbedUrl = (url) => {
    const urlObj = new URL(url);
    if (urlObj.hostname === 'www.youtube.com' && urlObj.pathname === '/watch') {
      const videoId = urlObj.searchParams.get('v');
      return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
    }
    if (urlObj.hostname === 'youtu.be') {
      const videoId = urlObj.pathname.slice(1);
      return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
    }
    return url; // Return the original URL if it doesn’t match YouTube formats
  };

  const handleSaveMedia = async (mediaData) => {
    if (mediaData === null) {
      // Refresh media items after deletion
      const mediaCollection = collection(db, 'media');
      const mediaSnapshot = await getDocs(mediaCollection);
      const updatedMediaData = mediaSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setMediaItems(updatedMediaData);
      setShowOverlay(false);
      return;
    }

    try {
      const transformedData = {
        ...mediaData,
        url: convertToEmbedUrl(mediaData.url), // Convert the URL to embed format
      };

      if (overlayMode === 'add') {
        // Add new document
        await addDoc(collection(db, 'media'), transformedData);
      } else if (overlayMode === 'edit' && selectedMedia) {
        // Update existing document
        const mediaDocRef = doc(db, 'media', selectedMedia.id);
        await updateDoc(mediaDocRef, transformedData);
      }

      // Refresh the media items list after save
      const mediaCollection = collection(db, 'media');
      const mediaSnapshot = await getDocs(mediaCollection);
      const updatedMediaData = mediaSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setMediaItems(updatedMediaData);

      // Close overlay after save
      setShowOverlay(false);
    } catch (error) {
      console.error("Error saving media:", error);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Media</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the media that is linked on your website
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={openAddOverlay}
            className="block rounded-md bg-user-dark-orange px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-user-light-orange"
          >
            Add Media
          </button>
        </div>
      </div>

      {/* Toggle for Media visibility */}
      <div className="flex items-center my-4 space-x-4">
        <label className="text-sm font-medium text-gray-900">Media Visibility</label>
        <Switch
          checked={isMediaVisible}
          onChange={(value) => handleToggleChange(value)}
          className={`relative inline-flex h-6 w-11 items-center rounded-full ${isMediaVisible ? 'bg-user-dark-orange' : 'bg-gray-200'}`}
        >
          <span className="sr-only">Toggle media visibility</span>
          <span className={`transform inline-block h-5 w-5 rounded-full bg-white transition ${isMediaVisible ? 'translate-x-5' : 'translate-x-0'}`} />
        </Switch>
      </div>

      {isToggleChanged && (
        <button
          onClick={handleSaveToggleChanges}
          className="inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange"
        >
          Save Changes
        </button>
      )}

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Title</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">URL (Embed)</th>
                  <th className="relative py-3.5 pl-3 pr-4 sm:pr-0"><span className="sr-only">Edit</span></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {mediaItems.map((media) => (
                  <tr key={media.id}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{media.title}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{media.description}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{media.url}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => openEditOverlay(media)}
                        className="text-user-dark-orange hover:text-user-light-orange"
                      >
                        Edit<span className="sr-only">, {media.title}</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Render MediaOverlay if showOverlay is true */}
      {showOverlay && (
        <MediaOverlay
          onClose={() => setShowOverlay(false)}
          onSave={handleSaveMedia}
          mediaData={selectedMedia}
          mode={overlayMode}
        />
      )}
    </div>
  );
}
