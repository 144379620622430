import React, { useState } from 'react';

export default function RiderOverlay({ onClose, onSave, riderData, mode }) {
  const [category, setCategory] = useState(riderData?.category || 'Audio');
  const [name, setName] = useState(riderData?.name || '');
  const [description, setDescription] = useState(riderData?.description || '');
  const [url, setUrl] = useState(riderData?.url || '');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleSave = () => {
    const newRiderData = { category, name, description, url };
    onSave(newRiderData);
  };

  const handleDelete = () => {
    onSave(null); // Passing `null` signals the parent component to delete this item
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full">
        <h2 className="text-xl font-semibold mb-4">{mode === 'add' ? 'Add Equipment' : 'Edit Equipment'}</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {/* Category Dropdown */}
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-900">Category</label>
            <select
              id="category"
              name="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option value="Audio">Audio</option>
              <option value="Lights">Lights</option>
              <option value="FX">FX</option>
            </select>
          </div>

          {/* Name Text Area */}
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-900">Name</label>
            <textarea
              id="name"
              name="name"
              rows={1}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>

          {/* Description Text Area */}
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-900">Description</label>
            <textarea
              id="description"
              name="description"
              rows={1}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>

          {/* URL Text Area */}
          <div>
            <label htmlFor="url" className="block text-sm font-medium text-gray-900">URL</label>
            <textarea
              id="url"
              name="url"
              rows={1}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none"
          >
            Cancel
          </button>
          {mode === 'edit' && (
            <button
              onClick={() => setShowDeleteConfirmation(true)}
              className="px-4 py-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-500 focus:outline-none"
            >
              Delete
            </button>
          )}
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-user-dark-orange text-white rounded-md shadow-sm hover:bg-user-light-orange focus:outline-none"
          >
            Save
          </button>
        </div>

        {/* Delete Confirmation Modal */}
        {showDeleteConfirmation && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-sm w-full">
              <h3 className="text-lg font-semibold mb-4">Are you sure you want to delete?</h3>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowDeleteConfirmation(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-500 focus:outline-none"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
