// src/components/RedirectRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const RedirectRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    // You can render a loading spinner here if needed
    return <div>Loading...</div>;
  }

  return user ? <Navigate to="/dashboard" /> : children;
};

export default RedirectRoute;
