import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import PhotoOverlay from './PhotoOverlay';
import { Switch } from '@headlessui/react';

export default function Photos() {
  const [photos, setPhotos] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [isTitleModified, setIsTitleModified] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Toggle state
  const [isPhotosVisible, setIsPhotosVisible] = useState(false);
  const [isToggleChanged, setIsToggleChanged] = useState(false);

  // Fetch photos and visibility state from Firestore
  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'photos'));
        const photosData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPhotos(photosData);
      } catch (error) {
        console.error("Error fetching photos:", error);
      }
    };

    const fetchPhotosVisibility = async () => {
      const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
      const sectionsDoc = await getDoc(sectionsDocRef);
      if (sectionsDoc.exists()) {
        const sectionData = sectionsDoc.data();
        setIsPhotosVisible(sectionData.photos || false);
      }
    };

    fetchPhotos();
    fetchPhotosVisibility();
  }, []);

  const handleToggleChange = (value) => {
    setIsPhotosVisible(value);
    setIsToggleChanged(true);
  };

  const handleSaveToggleChanges = async () => {
    try {
      const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
      await updateDoc(sectionsDocRef, { photos: isPhotosVisible });
      setIsToggleChanged(false);
    } catch (error) {
      console.error("Error saving photos visibility:", error);
    }
  };

  const handlePhotoSelect = (photo) => {
    if (selectedPhoto?.id === photo.id) {
      setSelectedPhoto(null);
      setEditedTitle('');
      setIsTitleModified(false);
      setShowSuccessMessage(false);
    } else {
      setSelectedPhoto(photo);
      setEditedTitle(photo.fileName);
      setIsTitleModified(false);
      setShowSuccessMessage(false);
    }
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setEditedTitle(newTitle);
    setIsTitleModified(newTitle !== selectedPhoto.fileName);
  };

  const handleSaveTitle = async () => {
    if (selectedPhoto && isTitleModified) {
      try {
        await updateDoc(doc(db, 'photos', selectedPhoto.id), { fileName: editedTitle });
        setPhotos(prevPhotos =>
          prevPhotos.map(photo =>
            photo.id === selectedPhoto.id ? { ...photo, fileName: editedTitle } : photo
          )
        );
        setIsTitleModified(false);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 2000);
      } catch (error) {
        console.error("Error saving title:", error);
      }
    }
  };

  const handleDelete = async () => {
    if (selectedPhoto) {
      try {
        await deleteDoc(doc(db, 'photos', selectedPhoto.id));
        setPhotos(prevPhotos => prevPhotos.filter(photo => photo.id !== selectedPhoto.id));
        setSelectedPhoto(null);
        setEditedTitle('');
      } catch (error) {
        console.error("Error deleting photo:", error);
      }
    }
  };

  const handleSaveNewPhoto = (newPhoto) => {
    setPhotos(prevPhotos => [...prevPhotos, newPhoto]);
    setShowOverlay(false);
  };

  return (
    <div className="pb-12 sm:pb-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="sm:flex sm:items-center mb-4">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">Photos</h1>
        </div>

        {/* Toggle for Photos visibility */}
        <div className="flex items-center my-4 space-x-4">
          <label className="text-sm font-medium text-gray-900">Photos Visibility</label>
          <Switch
            checked={isPhotosVisible}
            onChange={(value) => handleToggleChange(value)}
            className={`relative inline-flex h-6 w-11 items-center rounded-full ${isPhotosVisible ? 'bg-user-dark-orange' : 'bg-gray-200'}`}
          >
            <span className="sr-only">Toggle photos visibility</span>
            <span className={`transform inline-block h-5 w-5 rounded-full bg-white transition ${isPhotosVisible ? 'translate-x-5' : 'translate-x-0'}`} />
          </Switch>
        </div>

        {isToggleChanged && (
          <button
            onClick={handleSaveToggleChanges}
            className="inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange"
          >
            Save Changes
          </button>
        )}

        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {photos.map((photo) => (
            <li key={photo.id} className="relative" onClick={() => handlePhotoSelect(photo)}>
              <div
                className={`group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 ${
                  selectedPhoto?.id === photo.id ? 'ring-2 ring-green-500' : ''
                }`}
              >
                <img alt={photo.fileName} src={photo.fileUrl} className="pointer-events-none object-cover group-hover:opacity-75" />
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-600">{photo.fileName}</p>
            </li>
          ))}
        </ul>
        
        {selectedPhoto && !showSuccessMessage && (
          <div className="mt-4">
            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
              Edit Title
            </label>
            <input
              id="title"
              name="title"
              type="text"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-user-dark-orange sm:text-sm sm:leading-6"
              value={editedTitle}
              onChange={handleTitleChange}
            />
            {isTitleModified && (
              <button
                onClick={handleSaveTitle}
                className="mt-2 inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            )}
          </div>
        )}

        {showSuccessMessage && (
          <p className="mt-2 text-green-600 text-sm font-medium">
            Title successfully updated!
          </p>
        )}

        <div className="mt-6 flex justify-end space-x-4">
          {selectedPhoto && (
            <button
              onClick={handleDelete}
              className="inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Delete
            </button>
          )}

          <button
            onClick={() => setShowOverlay(true)}
            className="inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Upload Photo
          </button>
        </div>
      </div>

      {showOverlay && (
        <PhotoOverlay
          onClose={() => setShowOverlay(false)}
          onSave={handleSaveNewPhoto}
          existingPhotos={photos}
        />
      )}
    </div>
  );
}
