import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import RiderOverlay from './RiderOverlay';
import { Switch } from '@headlessui/react';

export default function Rider() {
  const [riderItems, setRiderItems] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedRider, setSelectedRider] = useState(null);
  const [overlayMode, setOverlayMode] = useState('add');

  // Toggle state for Rider visibility
  const [isRiderVisible, setIsRiderVisible] = useState(false);
  const [isToggleChanged, setIsToggleChanged] = useState(false);

  // Fetch rider items and visibility status from Firestore
  useEffect(() => {
    const fetchRiderItems = async () => {
      const riderCollection = collection(db, 'rider');
      const riderSnapshot = await getDocs(riderCollection);
      const riderData = riderSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      riderData.sort((a, b) => {
        const order = { Audio: 1, Lights: 2, FX: 3 };
        return order[a.category] - order[b.category];
      });

      setRiderItems(riderData);
    };

    const fetchRiderVisibility = async () => {
      const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
      const sectionsDoc = await getDoc(sectionsDocRef);
      if (sectionsDoc.exists()) {
        const sectionData = sectionsDoc.data();
        setIsRiderVisible(sectionData.rider || false);
      }
    };

    fetchRiderItems();
    fetchRiderVisibility();
  }, []);

  const handleToggleChange = (value) => {
    setIsRiderVisible(value);
    setIsToggleChanged(true);
  };

  const handleSaveToggleChanges = async () => {
    try {
      const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
      await updateDoc(sectionsDocRef, { rider: isRiderVisible });
      setIsToggleChanged(false);
    } catch (error) {
      console.error("Error saving rider visibility:", error);
    }
  };

  const openAddOverlay = () => {
    setOverlayMode('add');
    setSelectedRider(null);
    setShowOverlay(true);
  };

  const openEditOverlay = (rider) => {
    setOverlayMode('edit');
    setSelectedRider(rider);
    setShowOverlay(true);
  };

  const handleSaveRider = async (riderData) => {
    try {
      if (riderData === null && selectedRider) {
        // Delete mode if `riderData` is null
        await deleteDoc(doc(db, 'rider', selectedRider.id));
      } else if (overlayMode === 'add') {
        // Add new document
        await addDoc(collection(db, 'rider'), riderData);
      } else if (overlayMode === 'edit' && selectedRider) {
        // Update existing document
        const riderDocRef = doc(db, 'rider', selectedRider.id);
        await updateDoc(riderDocRef, riderData);
      }

      // Refresh the rider items list after adding, updating, or deleting
      const riderCollection = collection(db, 'rider');
      const riderSnapshot = await getDocs(riderCollection);
      const updatedRiderData = riderSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      updatedRiderData.sort((a, b) => {
        const order = { Audio: 1, Lights: 2, FX: 3 };
        return order[a.category] - order[b.category];
      });

      setRiderItems(updatedRiderData);
      setShowOverlay(false);
    } catch (error) {
      console.error("Error handling rider item:", error);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Rider</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the equipment required for the setup
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={openAddOverlay}
            className="block rounded-md bg-user-dark-orange px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-user-light-orange"
          >
            Add Equipment
          </button>
        </div>
      </div>

      {/* Toggle for Rider visibility */}
      <div className="flex items-center my-4 space-x-4">
        <label className="text-sm font-medium text-gray-900">Rider Visibility</label>
        <Switch
          checked={isRiderVisible}
          onChange={handleToggleChange}
          className={`relative inline-flex h-6 w-11 items-center rounded-full ${isRiderVisible ? 'bg-user-dark-orange' : 'bg-gray-200'}`}
        >
          <span className="sr-only">Toggle rider visibility</span>
          <span className={`transform inline-block h-5 w-5 rounded-full bg-white transition ${isRiderVisible ? 'translate-x-5' : 'translate-x-0'}`} />
        </Switch>
      </div>

      {isToggleChanged && (
        <button
          onClick={handleSaveToggleChanges}
          className="inline-flex justify-center rounded-md bg-user-dark-orange px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-user-light-orange"
        >
          Save Changes
        </button>
      )}

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Category
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Description
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    URL
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {riderItems.map((rider) => (
                  <tr key={rider.id}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-user-blue">{rider.category}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rider.name}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rider.description}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rider.url}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => openEditOverlay(rider)}
                        className="text-user-dark-orange hover:text-user-light-orange"
                      >
                        Edit<span className="sr-only">, {rider.name}</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Render RiderOverlay if showOverlay is true */}
      {showOverlay && (
        <RiderOverlay
          onClose={() => setShowOverlay(false)}
          onSave={handleSaveRider}
          riderData={selectedRider}
          mode={overlayMode}
        />
      )}
    </div>
  );
}
