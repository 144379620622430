import React, { useState, useEffect } from 'react';
import { getStorage, ref, listAll, getDownloadURL, uploadBytes } from 'firebase/storage';
import { db } from '../firebase/firebaseConfig';

const storage = getStorage();

export default function BioImageOverlay({ onClose, onSave, targetField }) {
  const [files, setFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    // Fetch all images from the "web-images" folder in Firebase Storage
    const fetchImages = async () => {
      const folderRef = ref(storage, 'web-images');
      
      try {
        const result = await listAll(folderRef);

        const filePromises = result.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return {
            title: itemRef.name,
            size: 'Unknown', // Firebase doesn't provide file size directly
            source: url,
          };
        });

        const fetchedFiles = await Promise.all(filePromises);
        setFiles(fetchedFiles);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const handleImageSelect = (source) => {
    setSelectedImage(source);
  };

  const handleSave = async () => {
    if (!selectedImage) return;

    try {
      onSave(selectedImage);
    } catch (error) {
      console.error("Error saving image URL:", error);
    }
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    const fileRef = ref(storage, `web-images/${file.name}`);

    try {
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);

      // Add uploaded image to list of files and make it selectable immediately
      setFiles((prevFiles) => [...prevFiles, { title: file.name, size: `${(file.size / (1024 * 1024)).toFixed(2)} MB`, source: url }]);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full">
        <h2 className="text-xl font-semibold mb-4">Select Image</h2>

        {/* Image Grid */}
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {files.map((file) => (
            <li
              key={file.source}
              className={`relative cursor-pointer ${selectedImage === file.source ? 'ring-2 ring-green-500' : ''}`}
              onClick={() => handleImageSelect(file.source)}
            >
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img src={file.source} alt={file.title} className="pointer-events-none object-cover group-hover:opacity-75" />
                <button type="button" className="absolute inset-0 focus:outline-none">
                  <span className="sr-only">View details for {file.title}</span>
                </button>
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p>
              <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p>
            </li>
          ))}
        </ul>

        {/* Action Buttons */}
        <div className="mt-6 flex justify-end space-x-4">
          <label className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm cursor-pointer hover:bg-indigo-500 focus:outline-none">
            {uploading ? "Uploading..." : "Upload Image"}
            <input type="file" className="hidden" onChange={handleUpload} />
          </label>

          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-500 focus:outline-none"
            disabled={!selectedImage}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
