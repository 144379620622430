import React, { useState } from 'react';
import { doc, setDoc, updateDoc, collection, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import useCountries from '../hooks/useCountries';

export default function GigsOverlay({ mode, gigData, onClose, onSave }) {
  const countries = useCountries();
  const [country, setCountry] = useState(gigData?.country || 'PT');
  const [city, setCity] = useState(gigData?.city || '');
  const [venue, setVenue] = useState(gigData?.venue || '');
  const [date, setDate] = useState(gigData?.date || '');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleSave = async () => {
    const gigEntry = { country, city, venue, date };

    try {
      if (mode === 'edit') {
        await updateDoc(doc(db, 'gigs', gigData.id), gigEntry);
        onSave({ ...gigEntry, id: gigData.id });
      } else {
        const newDoc = doc(collection(db, 'gigs'));
        await setDoc(newDoc, gigEntry);
        onSave({ ...gigEntry, id: newDoc.id });
      }
      onClose();
    } catch (error) {
      console.error('Error saving gig:', error);
    }
  };

  const handleDelete = async () => {
    try {
      if (gigData && gigData.id) {
        await deleteDoc(doc(db, 'gigs', gigData.id));
        onSave(null); // Signal deletion to parent
        onClose(); // Close overlay after deletion
      }
    } catch (error) {
      console.error('Error deleting gig:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full">
        <h2 className="text-xl font-semibold mb-4">{mode === 'edit' ? 'Edit Gig' : 'Add Gig'}</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="country" className="block text-sm font-medium text-gray-900">Country</label>
            <select
              id="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              {countries.map(({ name, code }) => (
                <option key={code} value={code}>{name}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="city" className="block text-sm font-medium text-gray-900">City</label>
            <textarea
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <label htmlFor="venue" className="block text-sm font-medium text-gray-900">Venue</label>
            <textarea
              id="venue"
              value={venue}
              onChange={(e) => setVenue(e.target.value)}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-900">Date</label>
            <textarea
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none"
          >
            Cancel
          </button>
          {mode === 'edit' && (
            <button
              onClick={() => setShowDeleteConfirmation(true)}
              className="px-4 py-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-500 focus:outline-none"
            >
              Delete
            </button>
          )}
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-user-dark-orange text-white rounded-md shadow-sm hover:bg-user-light-orange focus:outline-none"
          >
            Save
          </button>
        </div>

        {/* Confirmation modal for delete */}
        {showDeleteConfirmation && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-sm w-full">
              <h3 className="text-lg font-semibold mb-4">Are you sure you want to delete?</h3>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowDeleteConfirmation(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-500 focus:outline-none"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
