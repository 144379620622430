import DashboardLayout from "../layout/DashboardLayout";

function Dashboard() {
    return (
      <>
      <DashboardLayout />
      </>
    );
  }
  
  export default Dashboard;